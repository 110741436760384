/*
"ROLE_GOD" => Super Admin
"ROLE_HOP_ADMIN" => Site Admin
"ROLE_LMP_ADMIN" => LMP Admin
"ROLE_SUPERVISOR" => General Admin (Supervisor)
"ROLE_GENERAL_ADMIN" => General Admin (Admin Clerk)
"ROLE_OFFICER" => General Admin
"ROLE_SINO_CLUB_CS" => Sino Club CS team
"ROLE_SINO_CLUB_CAMPAIGN" => Sino Club Campaign team
"ROLE_SINO_PARKING" => Sino Parking
"ROLE_PARKING_TEAM" => Parking Team
*/
const permission = {
  BANNER: {
    ADD: ["ROLE_GOD", "ROLE_LMP_ADMIN"],
    EDIT: ["ROLE_GOD", "ROLE_LMP_ADMIN"],
    VIEW: [
      "ROLE_GOD",
      "ROLE_LMP_ADMIN",
      "ROLE_SINO_CLUB_CAMPAIGN",
      "ROLE_HOP_ADMIN",
    ],
    DELETE: ["ROLE_GOD", "ROLE_LMP_ADMIN"],
  },
  BANNER_LIST: {
    ADD: ["ROLE_GOD"],
    EDIT: ["ROLE_GOD"],
    VIEW: [
      "ROLE_GOD",
      "ROLE_LMP_ADMIN",
      "ROLE_SINO_CLUB_CAMPAIGN",
      "ROLE_HOP_ADMIN",
    ],
  },
  HOMEPAGEBANNER: {
    ADD: ["ROLE_GOD"],
    EDIT: ["ROLE_GOD"],
    VIEW: ["ROLE_GOD"],
    DELETE: ["ROLE_GOD"],
    // ADJUST_TNC: ["ROLE_GOD"],
  },
  CONTACTLESSPARKING: {
    VIEW: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_OFFICER",
      "ROLE_PARKING_TEAM",
    ],
    UPDATE: [
      "ROLE_SUPERVISOR",
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_PARKING_TEAM",
    ],
  },
  MEMBER: {
    ADD: [
      "ROLE_GOD",
      "ROLE_SUPERVISOR",
      "ROLE_OFFICER",
      "ROLE_HOP_ADMIN",
      "ROLE_SINO_CLUB_CS",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    EDIT: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_SINO_CLUB_CS",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    VIEW: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_GENERAL_ADMIN",
      "ROLE_OFFICER",
      "ROLE_SINO_CLUB_CS",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    MEMNER_INFORMATION: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_GENERAL_ADMIN",
      "ROLE_OFFICER",
      "ROLE_SINO_CLUB_CS",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    TRANSACTION: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_GENERAL_ADMIN",
      "ROLE_OFFICER",
      "ROLE_SINO_CLUB_CS",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    COMBINE_RECEIPT: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_GENERAL_ADMIN",
      "ROLE_OFFICER",
    ],
    ADD_SPENDING_TRANSACTION: ["ROLE_GOD", "ROLE_SUPERVISOR", "ROLE_OFFICER"],
    ADD_OLD_SPENDING_TRANSACTION: ["ROLE_GOD", "ROLE_SUPERVISOR"],
    ADD_14_DAY_SPENDING_TRANSACTION: ["ROLE_OFFICER"],
    POINT_ADJUSTMENT: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_SINO_CLUB_CS",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    ADD_POINT_ADJUSTMENT_TYPE: ["ROLE_GOD"],
    RESERVE_GIFT: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_OFFICER",
      "ROLE_SINO_CLUB_CS",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    REDEEM_GIFT: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_OFFICER",
      "ROLE_SINO_CLUB_CS",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    REDEEM_EXPIRED_GIFT: [
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_GOD",
      "ROLE_OFFICER",
      "ROLE_SUPERVISOR",
      "ROLE_SINO_CLUB_CS",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    REDEEM_MERCHANT_GIFT: [
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_GOD",
      "ROLE_SUPERVISOR",
      "ROLE_OFFICER",
      "ROLE_SINO_CLUB_CS",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    FORCE_LOGOUT: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_OFFICER",
      "ROLE_SINO_CLUB_CS",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    FREEZE: ["ROLE_GOD", "ROLE_HOP_ADMIN"],
    UNFREEZE: ["ROLE_GOD", "ROLE_HOP_ADMIN"],
    TERMINATE: ["ROLE_GOD"],
    CHANGE_PHONE: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_OFFICER",
      "ROLE_SINO_CLUB_CS",
    ],
    REMARK: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_GENERAL_ADMIN",
      "ROLE_OFFICER",
      "ROLE_SINO_CLUB_CS",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    ADD_REMARK_TYPE: [
      "ROLE_GOD",
      "ROLE_SINO_CLUB_CS",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    UPDATE_RECEIVE_PROMOTION_MESSAGE: ["ROLE_GOD"],
    UPDATE_ALLOW_TRANSFER_PERSONAL_DATA: ["ROLE_GOD"],
    REDEEM_SCOUPON: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_OFFICER",
      "ROLE_SINO_CLUB_CS",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    VIEW_MY_SCOUPON: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_OFFICER",
      "ROLE_SINO_CLUB_CS",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    EXTEND_POINT_EXPIRY: ["ROLE_GOD", "ROLE_HOP_ADMIN", "ROLE_SUPERVISOR"],
    REDEEM_FREE_PARKING: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_OFFICER",
    ],
    REMOVE_PHONE_NUMBER: ["ROLE_HOP_ADMIN", "ROLE_SUPERVISOR", "ROLE_GOD"],
    TRIGGER_LOGIN_SMS: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_OFFICER",
      "ROLE_SINO_CLUB_CS",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    VIEW_LOGIN_SMS: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_OFFICER",
      "ROLE_SINO_CLUB_CS",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    VIEW_LICENSE_PLATE: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_OFFICER",
    ],
    VIEW_BUNDLED_CREDIT_CARD: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_OFFICER",
    ],
    VIEW_MEMBER_SINO_CLUB_BADGE: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_OFFICER",
      "ROLE_SINO_CLUB_CS",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    UPDARE_MEMBER_SINO_CLUB_BADGE: [
      "ROLE_GOD",
      "ROLE_SINO_CLUB_CS",
      "ROLE_SINO_CLUB_CAMPAIGN",
      "ROLE_HOP_ADMIN",
      "ROLE_SUPERVISOR",
    ],
    CHANGE_MEMBERSHIP_TIER: ["ROLE_GOD"],
  },
  POINT_MANAGEMENT: {
    VIEW: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_GENERAL_ADMIN",
      "ROLE_LMP_ADMIN",
    ],
    NORMAL_CONFIRM_PROCESS: [
      "ROLE_GOD",
      "ROLE_SUPERVISOR",
      "ROLE_GENERAL_ADMIN",
      "ROLE_SINO_CLUB_CS",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    RESUME_TRANSACTION: ["ROLE_GOD", "ROLE_HOP_ADMIN", "ROLE_SUPERVISOR"],
    EDIT_TRANSACTION: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_GENERAL_ADMIN",
    ],
  },
  SDOLLAR: {
    JOIN: ["ROLE_GOD"],
    WITHDRAW: ["ROLE_GOD"],
    CHEQUE: ["ROLE_GOD"],
    VIEW: ["ROLE_GOD", "ROLE_HOP_ADMIN", "ROLE_LMP_ADMIN"],
    VIEW_DETAIL: ["ROLE_GOD", "ROLE_HOP_ADMIN", "ROLE_LMP_ADMIN"],
    CONFIRM_ALL_PENDING_BALANCE: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
    ],
    CONFIRM_PENDING_BALANCE: ["ROLE_GOD", "ROLE_HOP_ADMIN", "ROLE_LMP_ADMIN"],
    ADJUST_PENDING_BALANCE: ["ROLE_GOD", "ROLE_LMP_ADMIN"],
    ADD_ADJUST_PENDING_BALANCE_REASON: ["ROLE_GOD", "ROLE_LMP_ADMIN"],
    ADJUST_GLOBAL_RATE: ["ROLE_GOD"],
    ADJUST_SHORT_TNC: ["ROLE_GOD"],
  },
  SHOP: {
    ADD: ["ROLE_GOD"],
    EDIT: ["ROLE_GOD"],
    VIEW: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_OFFICER",
    ],
    ENABLE: ["ROLE_GOD"],
  },
  MALL: {
    EDIT: ["ROLE_GOD"],
    VIEW: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_OFFICER",
    ],
    ENABLE: ["ROLE_GOD"],
  },
  MERCHANT: {
    VIEW: ["ROLE_GOD", "ROLE_HOP_ADMIN", "ROLE_LMP_ADMIN"],
    ADD: ["ROLE_GOD", "ROLE_HOP_ADMIN", "ROLE_LMP_ADMIN"],
    CHANGE_PASSWORD: ["ROLE_GOD", "ROLE_HOP_ADMIN", "ROLE_LMP_ADMIN"],
  },
  PARKING: ["ROLE_GOD", "ROLE_HOP_ADMIN", "ROLE_LMP_ADMIN"],
  BONUS: ["ROLE_GOD"],
  GIFT: {
    ADD: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    EDIT: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    VIEW: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SUPERVISOR",
      "ROLE_OFFICER",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    ENABLE: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    DELETE: ["ROLE_GOD", "ROLE_SINO_CLUB_CAMPAIGN"],
    ORDERING: ["ROLE_GOD", "ROLE_SINO_CLUB_CAMPAIGN"],
    HIGHLIGHTED: ["ROLE_GOD", "ROLE_SINO_CLUB_CAMPAIGN"],
  },
  BADGES: {
    ADD: ["ROLE_GOD"],
    EDIT: ["ROLE_GOD"],
    EDIT_SP: ["ROLE_GOD", "ROLE_HOP_ADMIN", "ROLE_LMP_ADMIN"],
    VIEW: ["ROLE_GOD", "ROLE_HOP_ADMIN", "ROLE_LMP_ADMIN"],
  },
  BADGE_OFFER_PROMOTION: {
    ADD: ["ROLE_GOD", "ROLE_HOP_ADMIN", "ROLE_LMP_ADMIN"],
    EDIT: ["ROLE_GOD", "ROLE_HOP_ADMIN", "ROLE_LMP_ADMIN"],
    VIEW: ["ROLE_GOD", "ROLE_HOP_ADMIN", "ROLE_LMP_ADMIN"],
  },
  MEMBER_TAG: {
    ADD: ["ROLE_GOD"],
    EDIT: ["ROLE_GOD"],
    VIEW: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
  },
  CRAZY_AD: ["ROLE_GOD"],
  CAMPAIGN: {
    ADD: ["ROLE_GOD"],
    EDIT: ["ROLE_GOD"],
    VIEW: ["ROLE_GOD", "ROLE_HOP_ADMIN", "ROLE_LMP_ADMIN"],
  },
  HOT_DEALS: {
    ADD: ["ROLE_GOD"],
    EDIT: ["ROLE_GOD"],
    VIEW: ["ROLE_GOD"],
    DELETE: ["ROLE_GOD"],
  },
  COUPON: {
    ADD: ["ROLE_GOD"],
    EDIT: ["ROLE_GOD"],
    VIEW: ["ROLE_GOD", "ROLE_HOP_ADMIN", "ROLE_LMP_ADMIN"],
    DELETE: ["ROLE_GOD"],
    ADJUST_TNC: ["ROLE_GOD"],
  },
  POINT_REGISTRATION: {
    ADD: ["ROLE_GOD"],
    EDIT: ["ROLE_GOD"],
    VIEW: ["ROLE_GOD"],
  },
  PUSH_NOTIFICATION: {
    ADD: ["ROLE_GOD"],
    EDIT: ["ROLE_GOD"],
    VIEW: ["ROLE_GOD", "ROLE_HOP_ADMIN", "ROLE_LMP_ADMIN"],
  },
  INBOX_MESSAGE: {
    ADD: ["ROLE_GOD"],
    EDIT: ["ROLE_GOD"],
    VIEW: ["ROLE_GOD", "ROLE_HOP_ADMIN", "ROLE_LMP_ADMIN"],
  },
  FEED: ["ROLE_GOD", "ROLE_HOP_ADMIN", "ROLE_LMP_ADMIN"],
  CHECK_IN: ["ROLE_GOD", "ROLE_HOP_ADMIN", "ROLE_LMP_ADMIN"],
  USER: ["ROLE_GOD", "ROLE_HOP_ADMIN"],
  CHANGE_PASSWORD: [
    "ROLE_GOD",
    "ROLE_HOP_ADMIN",
    "ROLE_LMP_ADMIN",
    "ROLE_SUPERVISOR",
    "ROLE_GENERAL_ADMIN",
    "ROLE_OFFICER",
  ],
  MERCHANT_CUSTOM_REFERRAL_POINT: {
    ADD: ["ROLE_GOD"],
    EDIT: ["ROLE_GOD"],
    DELETE: ["ROLE_GOD"],
    VIEW: ["ROLE_GOD"],
  },
  THIRD_PARTY_INTEGRATOR: ["ROLE_GOD"],
  // SINO CLUB MEMBER
  SINO_CLUB_MEMBER: {
    VIEW: [
      "ROLE_GOD",
      "ROLE_SINO_CLUB_CS",
      "ROLE_SINO_CLUB_CAMPAIGN",
      "ROLE_SINO_PARKING",
    ],
    EDIT: ["ROLE_GOD", "ROLE_SINO_CLUB_CS"],
    ADD: ["ROLE_GOD", "ROLE_SINO_CLUB_CS"],
  },
  SINO_CLUB_MEMBER_UPGRADE: {
    VIEW: ["ROLE_GOD", "ROLE_SINO_CLUB_CS"],
    EDIT: ["ROLE_GOD", "ROLE_SINO_CLUB_CS"],
  },
  ESTATE: {
    VIEW: ["ROLE_GOD", "ROLE_SINO_CLUB_CS"],
    EDIT: ["ROLE_GOD", "ROLE_SINO_CLUB_CS"],
    ADD: ["ROLE_GOD", "ROLE_SINO_CLUB_CS"],
  },
  SINO_PARKING: {
    VIEW: ["ROLE_GOD", "ROLE_SINO_CLUB_CS", "ROLE_SINO_PARKING"],
    EDIT: ["ROLE_GOD", "ROLE_SINO_CLUB_CS", "ROLE_SINO_PARKING"],
  },
  CREATE_PARKING_RECORDS: {
    VIEW: ["ROLE_GOD", "ROLE_PARKING_TEAM"],
    ADD: ["ROLE_GOD", "ROLE_PARKING_TEAM"],
  },
  ACTIVITY: {
    ADD: ["ROLE_GOD"],
    EDIT: ["ROLE_GOD"],
    VIEW: [
      "ROLE_GOD",
      "ROLE_SINO_CLUB_CAMPAIGN",
      "ROLE_LMP_ADMIN",
      "ROLE_HOP_ADMIN",
    ],
    DELETE: ["ROLE_GOD"],
  },
  SINO_CLUB_P_MEMBER_REFERRAL_CODE: {
    VIEW: ["ROLE_GOD", "ROLE_SINO_CLUB_CAMPAIGN"],
    EDIT: ["ROLE_GOD", "ROLE_SINO_CLUB_CAMPAIGN"],
    ADD: ["ROLE_GOD", "ROLE_SINO_CLUB_CAMPAIGN"],
    DELETE: ["ROLE_GOD", "ROLE_SINO_CLUB_CAMPAIGN"],
  },
  UNPUBLISHED_PRIVILEGE: {
    VIEW: [
      "ROLE_GOD",
      "ROLE_HOP_ADMIN",
      "ROLE_LMP_ADMIN",
      "ROLE_SINO_CLUB_CAMPAIGN",
    ],
    EDIT: ["ROLE_GOD"],
    ADD: ["ROLE_GOD"],
    DELETE: ["ROLE_GOD"],
  },
};

export default permission;
