import { requestConstantsMaker } from "../../helpers/reduxHelper";

export const POST_LOGIN = requestConstantsMaker("POST_LOGIN");
export const POST_LOGIN_BY_REFRESH_TOKEN = requestConstantsMaker(
  "POST_LOGIN_BY_REFRESH_TOKEN"
);
export const GET_MY_AUTHORITY = requestConstantsMaker("GET_MY_AUTHORITY");
export const GET_LOGOUT = requestConstantsMaker("GET_LOGOUT");
// Member Apis
export const GET_MEMBER_LIST = requestConstantsMaker("GET_MEMBER_LIST");
export const GET_MEMBER_DETAIL = requestConstantsMaker("GET_MEMBER_DETAIL");
export const POST_FORCE_LOGOUT_MEMBER = requestConstantsMaker(
  "POST_FORCE_LOGOUT_MEMBER"
);
export const GET_USER_REMARK_LIST = requestConstantsMaker(
  "GET_USER_REMARK_LIST"
);
export const POST_CREATE_USER_REMARK = requestConstantsMaker(
  "POST_CREATE_USER_REMARK"
);
export const GET_ALL_USER_REMARK_TYPE = requestConstantsMaker(
  "GET_ALL_USER_REMARK_TYPE"
);
export const POST_CREATE_USER_REMARK_TYPE = requestConstantsMaker(
  "POST_CREATE_USER_REMARK_TYPE"
);
export const POST_CREATE_MEMBER = requestConstantsMaker("POST_CREATE_MEMBER");
export const PUT_UPDATE_MEMBER = requestConstantsMaker("PUT_UPDATE_MEMBER");
export const POST_TERMINATE_MEMBER = requestConstantsMaker(
  "POST_TERMINATE_MEMBER"
);
export const GET_ACCOUNT_WITH_PHONE = requestConstantsMaker(
  "GET_ACCOUNT_WITH_PHONE"
);
export const GET_SEND_SMS_VERIFY = requestConstantsMaker("GET_SEND_SMS_VERIFY");
export const PUT_CHANGE_MEMBER_PHONE = requestConstantsMaker(
  "POST_CHANGE_MEMBER_PHONE"
);
export const POST_FREEZE_MEMBER = requestConstantsMaker("POST_FREEZE_MEMBER");
export const POST_UNFREEZE_MEMBER = requestConstantsMaker(
  "POST_UNFREEZE_MEMBER"
);
export const PUT_UPDATE_MEMBER_RECEIVE_PROMOTION_MESSAGE =
  requestConstantsMaker("PUT_UPDATE_MEMBER_RECEIVE_PROMOTION_MESSAGE");
export const PUT_UPDATE_MEMBER_ALLOW_TRANSFER_PERSONAL_DATA =
  requestConstantsMaker("PUT_UPDATE_MEMBER_ALLOW_TRANSFER_PERSONAL_DATA");
export const GET_USER_RECORD_LIST = requestConstantsMaker(
  "GET_USER_RECORD_LIST"
);
export const GET_UNLINKED_SPEND_TRANSACTION = requestConstantsMaker(
  "GET_UNLINKED_SPEND_TRANSACTION"
);
export const GET_LINKED_SPEND_TRANSACTION = requestConstantsMaker(
  "GET_LINKED_SPEND_TRANSACTION"
);
export const POST_LINK_UP_SPENDING = requestConstantsMaker(
  "POST_LINK_UP_SPENDING"
);
export const POST_EXTEND_POINT_EXPIRATION_DATE = requestConstantsMaker(
  "POST_EXTEND_POINT_EXPIRATION_DATE"
);
export const POST_GET_PARKING_TRANSACTION = requestConstantsMaker(
  "POST_GET_PARKING_TRANSACTION"
);
export const POST_REDEEM_PARKING_HOUR = requestConstantsMaker(
  "POST_REDEEM_PARKING_HOUR"
);
export const POST_RETURN_GIFT = requestConstantsMaker("POST_RETURN_GIFT");
export const POST_RETURN_SCOUPON = requestConstantsMaker("POST_RETURN_SCOUPON");
export const GET_VERIFICATION_CODE_LIST = requestConstantsMaker(
  "GET_VERIFICATION_CODE_LIST"
);
// Mall Apis
export const GET_ALL_MALL = requestConstantsMaker("GET_ALL_MALL");
export const GET_SHOP_LIST = requestConstantsMaker("GET_SHOP_LIST");
export const PUT_UPDATE_SHOP = requestConstantsMaker("PUT_UPDATE_SHOP");
export const GET_ALL_SHOP_BY_MALL_CODE = requestConstantsMaker(
  "GET_ALL_SHOP_BY_MALL_CODE"
);
export const GET_ALL_SHOP_CATEGORY = requestConstantsMaker(
  "GET_ALL_SHOP_CATEGORY"
);
export const GET_SHOP = requestConstantsMaker("GET_SHOP");
export const GET_ALL_SHOP = requestConstantsMaker("GET_ALL_SHOP");
export const POST_CREATE_SHOP = requestConstantsMaker("POST_CREATE_SHOP");
// Gift Apis
export const GET_GIFT_LIST = requestConstantsMaker("GET_GIFT_LIST");
export const GET_ALL_FOODIE_SHOP = requestConstantsMaker("GET_ALL_FOODIE_SHOP");
export const POST_UPDATE_GIFT_ENABLED = requestConstantsMaker(
  "POST_UPDATE_GIFT_ENABLED"
);
export const GET_GIFT_LIST_FOR_REDEEM = requestConstantsMaker(
  "GET_GIFT_LIST_FOR_REDEEM"
);
export const GET_GIFT_LIST_FOR_REDEEM_REMARK_LIST = requestConstantsMaker(
  "GET_GIFT_LIST_FOR_REDEEM_REMARK_LIST"
);
export const POST_RESERVE_GIFT = requestConstantsMaker("POST_RESERVE_GIFT");
export const GET_GIFT_FOR_USER = requestConstantsMaker("GET_GIFT_FOR_USER");
export const POST_REDEEM_GIFT = requestConstantsMaker("POST_REDEEM_GIFT");
export const GET_ALL_GIFT_CATEGORY = requestConstantsMaker(
  "GET_ALL_GIFT_CATEGORY"
);
export const POST_CREATE_GIFT = requestConstantsMaker("POST_CREATE_GIFT");
export const PUT_UPDATE_GIFT = requestConstantsMaker("PUT_UPDATE_GIFT");
export const GET_GIFT = requestConstantsMaker("GET_GIFT");
export const POST_ADD_GIFT_ITEM = requestConstantsMaker("POST_ADD_GIFT_ITEM");
export const POST_MINUS_GIFT_ITEM = requestConstantsMaker(
  "POST_MINUS_GIFT_ITEM"
);
export const POST_CREATE_SKU_FOR_GIFT = requestConstantsMaker(
  "POST_CREATE_SKU_FOR_GIFT"
);
export const GET_GIFT_STOCK_DETAIL = requestConstantsMaker(
  "GET_GIFT_STOCK_DETAIL"
);
export const GET_GIFT_ITEM_BY_ID = requestConstantsMaker("GET_GIFT_ITEM_BY_ID");
export const PUT_UPDATE_GIFT_SKU = requestConstantsMaker("PUT_UPDATE_GIFT_SKU");
export const DELETE_GIFT = requestConstantsMaker("DELETE_GIFT");
export const DELETE_GIFT_SKU = requestConstantsMaker("DELETE_GIFT_SKU");
export const GET_GIFT_CODE_CSV = requestConstantsMaker("GET_GIFT_CODE_CSV");
export const GET_GIFT_TNC = requestConstantsMaker("GET_GIFT_TNC");

// Unpublished Privilege
export const GET_PRIVILEGE_LIST = requestConstantsMaker("GET_PRIVILEGE_LIST");
export const GET_PRIVILEGE = requestConstantsMaker("GET_PRIVILEGE");

// Badge Apis
export const GET_ALL_BADGE = requestConstantsMaker("GET_ALL_BADGE");
export const GET_BADGE_LIST = requestConstantsMaker("GET_BADGE_LIST");
export const POST_CREATE_BADGE = requestConstantsMaker("POST_CREATE_BADGE");
export const PUT_UPDATE_BADGE = requestConstantsMaker("PUT_UPDATE_BADGE");
export const GET_BADGE = requestConstantsMaker("GET_BADGE");
export const POST_GIVE_SPECIAL_INVITATION_BADGE_TO_MEMBER =
  requestConstantsMaker("POST_GIVE_SPECIAL_INVITATION_BADGE_TO_MEMBER");
export const DELETE_SPECIAL_INVITATION_BADGE_FROM_MEMBER =
  requestConstantsMaker("DELETE_SPECIAL_INVITATION_BADGE_FROM_MEMBER");
export const GET_USERS_THAT_HAVE_ACHIEVED_GIVEN_BADGE = requestConstantsMaker(
  "GET_USERS_THAT_HAVE_ACHIEVED_GIVEN_BADGE"
);

// Member tags
export const GET_ALL_MEMBER_TAGS = requestConstantsMaker("GET_ALL_MEMBER_TAGS");
export const GET_MEMBER_TAGS_LIST = requestConstantsMaker(
  "GET_MEMBER_TAGS_LIST"
);
export const GET_MEMBER_TAGS_LIST_BY_MEMBER = requestConstantsMaker(
  "GET_MEMBER_TAGS_LIST_BY_MEMBER"
);
export const GET_MEMBER_TAGS = requestConstantsMaker("GET_MEMBER_TAGS");
export const POST_CREATE_MEMBER_TAGS = requestConstantsMaker(
  "POST_CREATE_MEMBER_TAGS"
);
export const PUT_UPDATE_MEMBER_TAGS = requestConstantsMaker(
  "PUT_UPDATE_MEMBER_TAGS"
);
export const POST_UPDATE_MEMBER_TAGS_TARGET_LIST = requestConstantsMaker(
  "POST_UPDATE_MEMBER_TAGS_TARGET_LIST"
);
export const POST_ADD_MEMBER_TAGS_TARGET_LIST = requestConstantsMaker(
  "POST_ADD_MEMBER_TAGS_TARGET_LIST"
);
export const POST_REMOVE_MEMBER_TAGS_TARGET_LIST = requestConstantsMaker(
  "POST_REMOVE_MEMBER_TAGS_TARGET_LIST"
);
export const POST_MEMBER_TAGS_ADD_MEMBER = requestConstantsMaker(
  "POST_MEMBER_TAGS_ADD_MEMBER"
);
export const POST_MEMBER_TAGS_REMOVE_MEMBER = requestConstantsMaker(
  "POST_MEMBER_TAGS_REMOVE_MEMBER"
);

// Crazy Ad
export const GET_CRAZY_AD_LIST = requestConstantsMaker("GET_CRAZY_AD_LIST");
export const GET_CRAZY_AD = requestConstantsMaker("GET_CRAZY_AD");
export const POST_CREATE_CRAZY_AD = requestConstantsMaker(
  "POST_CREATE_CRAZY_AD"
);
export const PUT_UPDATE_CRAZY_AD = requestConstantsMaker("PUT_UPDATE_CRAZY_AD");
// Campaign
export const GET_CAMPAIGN_LIST = requestConstantsMaker("GET_CAMPAIGN_LIST");
export const GET_CAMPAIGN = requestConstantsMaker("GET_CAMPAIGN");
export const POST_CREATE_CAMPAIGN = requestConstantsMaker(
  "POST_CREATE_CAMPAIGN"
);
export const PUT_UPDATE_CAMPAIGN = requestConstantsMaker("PUT_UPDATE_CAMPAIGN");
export const GET_ALL_CAMPAIGN = requestConstantsMaker("GET_ALL_CAMPAIGN");
// Promotion
export const GET_PROMOTION_LIST = requestConstantsMaker("GET_PROMOTION_LIST");
export const GET_PROMOTION = requestConstantsMaker("GET_PROMOTION");
export const POST_CREATE_PROMOTION = requestConstantsMaker(
  "POST_CREATE_PROMOTION"
);
export const PUT_UPDATE_PROMOTION = requestConstantsMaker(
  "PUT_UPDATE_PROMOTION"
);
// Bonus Rule
export const GET_BONUS_RULE_LIST = requestConstantsMaker("GET_BONUS_RULE_LIST");
export const GET_BONUS_RULE = requestConstantsMaker("GET_BONUS_RULE");
export const POST_CREATE_BONUS_RULE = requestConstantsMaker(
  "POST_CREATE_BONUS_RULE"
);
export const PUT_UPDATE_BONUS_RULE = requestConstantsMaker(
  "PUT_UPDATE_BONUS_RULE"
);
// User Push
export const GET_USER_PUSH_LIST = requestConstantsMaker("GET_USER_PUSH_LIST");
export const GET_USER_PUSH = requestConstantsMaker("GET_USER_PUSH");
export const POST_CREATE_USER_PUSH = requestConstantsMaker(
  "POST_CREATE_USER_PUSH"
);
export const PUT_UPDATE_USER_PUSH = requestConstantsMaker(
  "PUT_UPDATE_USER_PUSH"
);
export const PUT_CANCEL_USER_PUSH = requestConstantsMaker(
  "PUT_CANCEL_USER_PUSH"
);
// Merchant Push
export const GET_MERCHANT_PUSH_LIST = requestConstantsMaker(
  "GET_MERCHANT_PUSH_LIST"
);
export const GET_MERCHANT_PUSH = requestConstantsMaker("GET_MERCHANT_PUSH");
export const POST_CREATE_MERCHANT_PUSH = requestConstantsMaker(
  "POST_CREATE_MERCHANT_PUSH"
);
export const PUT_UPDATE_MERCHANT_PUSH = requestConstantsMaker(
  "PUT_UPDATE_MERCHANT_PUSH"
);
export const PUT_CANCEL_MERCHANT_PUSH = requestConstantsMaker(
  "PUT_CANCEL_MERCHANT_PUSH"
);
// FAQ
export const GET_ALL_ONLINE_ENQUIRY = requestConstantsMaker(
  "GET_ALL_ONLINE_ENQUIRY"
);
export const GET_ONLINE_ENQUIRY_LIST = requestConstantsMaker(
  "GET_ONLINE_ENQUIRY_LIST"
);
export const GET_ONLINE_ENQUIRY = requestConstantsMaker("GET_ONLINE_ENQUIRY");
export const PUT_UPDATE_ONLINE_ENQUIRY = requestConstantsMaker(
  "PUT_UPDATE_ONLINE_ENQUIRY"
);
// Spending
export const GET_SPENDING_TRANSACTION_LIST = requestConstantsMaker(
  "GET_SPENDING_TRANSACTION_LIST"
);
export const PUT_APPROVE_SPENDING_TRANSACTION = requestConstantsMaker(
  "PUT_APPROVE_SPENDING_TRANSACTION"
);
export const PUT_REJECT_SPENDING_TRANSACTION = requestConstantsMaker(
  "PUT_REJECT_SPENDING_TRANSACTION"
);
export const GET_ALL_REJECT_REASON = requestConstantsMaker(
  "GET_ALL_REJECT_REASON"
);
export const POST_SUMBIT_SPENDING_TRANSACTION = requestConstantsMaker(
  "POST_SUMBIT_SPENDING_TRANSACTION"
);
export const POST_UPDATE_SPENDING_TRANSACTION = requestConstantsMaker(
  "POST_UPDATE_SPENDING_TRANSACTION"
);
export const POST_RECALL_SPENDING_TRANSACTION = requestConstantsMaker(
  "POST_RECALL_SPENDING_TRANSACTION"
);
export const POST_ESTIMATE_SPENDING_TRANSACTION = requestConstantsMaker(
  "POST_ESTIMATE_SPENDING_TRANSACTION"
);
// Point Transaction
export const GET_POINT_TRANSACRION_LIST = requestConstantsMaker(
  "GET_POINT_TRANSACRION_LIST"
);
export const GET_POINT_TRANSACRION_BY_SPENDING_TRANSACTION_ID =
  requestConstantsMaker("GET_POINT_TRANSACRION_BY_SPENDING_TRANSACTION_ID");
// Point Adjustment
export const GET_ALL_POINT_ADJUSTMENT_TYPE = requestConstantsMaker(
  "GET_ALL_POINT_ADJUSTMENT_TYPE"
);
export const POST_SUBMIT_POINT_ADJUSTMENT = requestConstantsMaker(
  "POST_SUBMIT_POINT_ADJUSTMENT"
);
export const POST_CREATE_POINT_ADJUSTMENT_TYPE = requestConstantsMaker(
  "POST_CREATE_POINT_ADJUSTMENT_TYPE"
);
// Image API
export const POST_UPLOAD_IMAGE = requestConstantsMaker("POST_UPLOAD_IMAGE");
export const POST_UPLOAD_PROFILE_IMAGE = requestConstantsMaker(
  "POST_UPLOAD_PROFILE_IMAGE"
);
export const POST_UPLOAD_RECEIPT_IMAGE = requestConstantsMaker(
  "POST_UPLOAD_RECEIPT_IMAGE"
);
export const POST_UPLOAD_MALL_IMAGE = requestConstantsMaker(
  "POST_UPLOAD_MALL_IMAGE"
);
export const POST_UPLOAD_GIFT_IMAGE = requestConstantsMaker(
  "POST_UPLOAD_GIFT_IMAGE"
);
export const POST_UPLOAD_S_COUPON_IMAGE = requestConstantsMaker(
  "POST_UPLOAD_S_COUPON_IMAGE"
);
export const POST_UPLOAD_ADVERTISEMENT_IMAGE = requestConstantsMaker(
  "POST_UPLOAD_ADVERTISEMENT_IMAGE"
);
export const POST_UPLOAD_BADGE_IMAGE = requestConstantsMaker(
  "POST_UPLOAD_BADGE_IMAGE"
);
export const POST_UPLOAD_BADGE_PROMOTION_IMAGE = requestConstantsMaker(
  "POST_UPLOAD_BADGE_PROMOTION_IMAGE"
);
export const POST_UPLOAD_HOME_BANNER_IMAGE = requestConstantsMaker(
  "POST_UPLOAD_HOME_BANNER_IMAGE"
);

// Merchant
export const GET_ALL_MERCHANT = requestConstantsMaker("GET_ALL_MERCHANT");
export const GET_MERCHANT_STOCK = requestConstantsMaker("GET_MERCHANT_STOCK");
export const GET_MERCHANT_REPORT = requestConstantsMaker("GET_MERCHANT_REPORT");
export const GET_MERCHANT_BY_SHOP_ID = requestConstantsMaker(
  "GET_MERCHANT_BY_SHOP_ID"
);
export const POST_MERCHANT_REGISTER = requestConstantsMaker(
  "POST_MERCHANT_REGISTER"
);
export const PUT_UPDATE_MERCHANT = requestConstantsMaker("PUT_UPDATE_MERCHANT");
// User Management
export const PUT_UPDATE_USER_PASSWORD = requestConstantsMaker(
  "PUT_UPDATE_USER_PASSWORD"
);
export const GET_USER_LIST = requestConstantsMaker("GET_USER_LIST");
export const PUT_UPDATE_USER_PASSWORD_WITH_CURRENT_PASSWORD =
  requestConstantsMaker("PUT_UPDATE_USER_PASSWORD_WITH_CURRENT_PASSWORD");
export const POST_CREATE_USER = requestConstantsMaker("POST_CREATE_USER");
export const PUT_UPDATE_USER_STATUS = requestConstantsMaker(
  "PUT_UPDATE_USER_STATUS"
);
export const GET_MY_ACCOUNT_INFO = requestConstantsMaker("GET_MY_ACCOUNT_INFO");
export const PUT_UPDATE_ACCOUNT_STATUS = requestConstantsMaker(
  "PUT_UPDATE_ACCOUNT_STATUS"
);
// Inbox Message
export const GET_INBOX_MESSAGE_SCHEDULE_LIST = requestConstantsMaker(
  "GET_INBOX_MESSAGE_SCHEDULE_LIST"
);
export const GET_INBOX_MESSAGE_SCHEDULE = requestConstantsMaker(
  "GET_INBOX_MESSAGE_SCHEDULE"
);
export const POST_CREATE_INBOX_MESSAGE_SCHEDULE = requestConstantsMaker(
  "POST_CREATE_INBOX_MESSAGE_SCHEDULE"
);
export const PUT_UPDATE_INBOX_MESSAGE_SCHEDULE = requestConstantsMaker(
  "PUT_UPDATE_INBOX_MESSAGE_SCHEDULE"
);
export const PUT_CANCEL_INBOX_MESSAGE_SCHEDULE = requestConstantsMaker(
  "PUT_CANCEL_INBOX_MESSAGE_SCHEDULE"
);
// Merchant Message
export const GET_MERCHANT_INBOX_MESSAGE_LIST = requestConstantsMaker(
  "GET_MERCHANT_INBOX_MESSAGE_LIST"
);
export const GET_MERCHANT_INBOX_MESSAGE_SCHEDULE = requestConstantsMaker(
  "GET_MERCHANT_INBOX_MESSAGE_SCHEDULE"
);
export const GET_MERCHANT_INBOX_MESSAGE_SCHEDULE_LIST = requestConstantsMaker(
  "GET_MERCHANT_INBOX_MESSAGE_SCHEDULE_LIST"
);
export const POST_CREATE_MERCHANT_INBOX_MESSAGE_SCHEDULE =
  requestConstantsMaker("POST_CREATE_MERCHANT_INBOX_MESSAGE_SCHEDULE");
export const PUT_UPDATE_MERCHANT_INBOX_MESSAGE_SCHEDULE = requestConstantsMaker(
  "PUT_UPDATE_MERCHANT_INBOX_MESSAGE_SCHEDULE"
);
export const PUT_CANCEL_MERCHANT_INBOX_MESSAGE_SCHEDULE = requestConstantsMaker(
  "PUT_CANCEL_MERCHANT_INBOX_MESSAGE_SCHEDULE"
);
// Parking
export const GET_ALL_CAR_PARK = requestConstantsMaker("GET_ALL_CAR_PARK");
export const GET_CAR_PARK = requestConstantsMaker("GET_CAR_PARK");
export const PUT_UPDATE_CAR_PARK = requestConstantsMaker("PUT_UPDATE_CAR_PARK");
export const GET_PARKING_RECORD_LIST = requestConstantsMaker(
  "GET_PARKING_RECORD_LIST"
);
// Check In
export const GET_CHECK_IN_EVENT_LIST = requestConstantsMaker(
  "GET_CHECK_IN_EVENT_LIST"
);
export const POST_CREATE_CHECK_IN_EVENT = requestConstantsMaker(
  "POST_CREATE_CHECK_IN_EVENT"
);
export const GET_CHECK_IN_EVENT = requestConstantsMaker("GET_CHECK_IN_EVENT");
export const PUT_UPDATE_CHECK_IN_EVENT = requestConstantsMaker(
  "PUT_UPDATE_CHECK_IN_EVENT"
);
// Custom Referral Point
export const GET_CUSTOM_REFERRAL_POINT_LIST = requestConstantsMaker(
  "GET_CUSTOM_REFERRAL_POINT_LIST"
);
export const POST_CREATE_CUSTOM_REFERRAL_POINT = requestConstantsMaker(
  "POST_CREATE_CUSTOM_REFERRAL_POINT"
);
export const GET_CUSTOM_REFERRAL_POINT = requestConstantsMaker(
  "GET_CUSTOM_REFERRAL_POINT"
);
export const PUT_UPDATE_CUSTOM_REFERRAL_POINT = requestConstantsMaker(
  "PUT_UPDATE_CUSTOM_REFERRAL_POINT"
);
export const DELETE_CUSTOM_REFERRAL_POINT = requestConstantsMaker(
  "DELETE_CUSTOM_REFERRAL_POINT"
);
// SDollar
export const GET_SDOLLAR_SHOP_LIST = requestConstantsMaker(
  "GET_SDOLLAR_SHOP_LIST"
);
export const GET_SDOLLAR_SHOP = requestConstantsMaker("GET_SDOLLAR_SHOP");
export const PUT_CONFIRM_PENDING_BALANCE = requestConstantsMaker(
  "PUT_CONFIRM_PENDING_BALANCE"
);
export const GET_ALL_SHOP_REMARK_TYPE = requestConstantsMaker(
  "GET_ALL_SHOP_REMARK_TYPE"
);
export const POST_CREATE_SHOP_REMARK_TYPE = requestConstantsMaker(
  "POST_CREATE_SHOP_REMARK_TYPE"
);
export const POST_CREATE_SHOP_REMARK = requestConstantsMaker(
  "POST_CREATE_SHOP_REMARK"
);
export const GET_SHOP_REMARK_LIST = requestConstantsMaker(
  "GET_SHOP_REMARK_LIST"
);
export const GET_ALL_BALANCE_ADJUST_REASON = requestConstantsMaker(
  "GET_ALL_BALANCE_ADJUST_REASON"
);
export const POST_CREATE_BALANCE_ADJUST_REASON = requestConstantsMaker(
  "POST_CREATE_BALANCE_ADJUST_REASON"
);
export const POST_CREATE_BALANCE_ADJUSTMENT = requestConstantsMaker(
  "POST_CREATE_BALANCE_ADJUSTMENT"
);

export const GET_TENANT_BALANCE_PERIOD = requestConstantsMaker(
  "GET_TENANT_BALANCE_PERIOD"
);
export const GET_TENANT_BALANCE_RECORD_LIST = requestConstantsMaker(
  "GET_TENANT_BALANCE_RECORD_LIST"
);

export const POST_RESEND_TENANT_EMAIL = requestConstantsMaker(
  "POST_RESEND_TENANT_EMAIL"
);

export const GET_SDOLLAR_GLOBAL_RATE = requestConstantsMaker(
  "GET_SDOLLAR_GLOBAL_RATE"
);
export const POST_UPDATE_SDOLLAR_GLOBAL_RATE = requestConstantsMaker(
  "POST_UPDATE_SDOLLAR_GLOBAL_RATE"
);

export const GET_ALL_TENANT_BALANCE_RECORD_LIST = requestConstantsMaker(
  "GET_ALL_TENANT_BALANCE_RECORD_LIST"
);
export const PUT_ISSUE_CHEQUE = requestConstantsMaker("PUT_ISSUE_CHEQUE");

// SDollar Coupon
export const GET_SDOLLAR_COUPON_LIST = requestConstantsMaker(
  "GET_SDOLLAR_COUPON_LIST"
);
export const GET_SDOLLAR_COUPON = requestConstantsMaker("GET_SDOLLAR_COUPON");
export const POST_CREATE_SDOLLAR_COUPON = requestConstantsMaker(
  "POST_CREATE_SDOLLAR_COUPON"
);
export const PUT_UPDATE_SDOLLAR_COUPON = requestConstantsMaker(
  "PUT_UPDATE_SDOLLAR_COUPON"
);

export const GET_SDOLLAR_SHORT_TNC = requestConstantsMaker(
  "GET_SDOLLAR_SHORT_TNC"
);
export const POST_UPDATE_SDOLLAR_SHORT_TNC = requestConstantsMaker(
  "POST_UPDATE_SDOLLAR_SHORT_TNC"
);
export const GET_S_COUPON_TNC = requestConstantsMaker("GET_S_COUPON_TNC");
export const POST_UPDATE_S_COUPON_TNC = requestConstantsMaker(
  "POST_UPDATE_S_COUPON_TNC"
);

export const GET_ALL_TENANT_SHOP = requestConstantsMaker("GET_ALL_TENANT_SHOP");
export const GET_ALL_FOODIE_TENANT_SHOP = requestConstantsMaker(
  "GET_ALL_FOODIE_TENANT_SHOP"
);

// S-Coupon
export const GET_S_COUPON = requestConstantsMaker("GET_S_COUPON");
export const POST_CREATE_S_COUPON = requestConstantsMaker(
  "POST_CREATE_S_COUPON"
);
export const PUT_UPDATE_S_COUPON = requestConstantsMaker("PUT_UPDATE_S_COUPON");
export const DELETE_S_COUPON = requestConstantsMaker("DELETE_S_COUPON");
export const POST_ADD_S_COUPON_STOCK = requestConstantsMaker(
  "POST_ADD_S_COUPON_STOCK"
);
export const POST_MINUS_S_COUPON_STOCK = requestConstantsMaker(
  "POST_MINUS_S_COUPON_STOCK"
);
export const POST_UPDATE_S_COUPON_ENABLED = requestConstantsMaker(
  "POST_UPDATE_S_COUPON_ENABLED"
);
export const GET_S_COUPON_LIST = requestConstantsMaker("GET_S_COUPON_LIST");
export const GET_S_COUPON_STOCK_DETAIL = requestConstantsMaker(
  "GET_S_COUPON_STOCK_DETAIL"
);
export const GET_USER_RESERVEABLE_S_COUPON = requestConstantsMaker(
  "GET_USER_RESERVEABLE_S_COUPON"
);
export const GET_USER_RESERVEABLE_S_COUPON_REMARK_LIST = requestConstantsMaker(
  "GET_USER_RESERVEABLE_S_COUPON_REMARK_LIST"
);
export const GET_USER_S_COUPON = requestConstantsMaker("GET_USER_S_COUPON");
export const POST_RESERVE_S_COUPON = requestConstantsMaker(
  "POST_RESERVE_S_COUPON"
);
export const GET_COUPON_CODE_CSV = requestConstantsMaker("GET_COUPON_CODE_CSV");

export const PUT_ADD_QRCODE_AMOUNT = requestConstantsMaker(
  "PUT_ADD_QRCODE_AMOUNT"
);
export const GET_TENANT_STATMENT_PDF = requestConstantsMaker(
  "GET_TENANT_STATMENT_PDF"
);

// Badge Promotion
export const GET_ALL_SCHEDULE = requestConstantsMaker("GET_ALL_SCHEDULE");
export const POST_CREATE_BADGE_PROMOTION = requestConstantsMaker(
  "POST_CREATE_BADGE_PROMOTION"
);
export const PUT_UPDATE_BADGE_PROMOTION = requestConstantsMaker(
  "PUT_UPDATE_BADGE_PROMOTION"
);
export const DELETE_BADGE_PROMOTION = requestConstantsMaker(
  "DELETE_BADGE_PROMOTION"
);
export const GET_BADGE_PROMOTION_LIST = requestConstantsMaker(
  "GET_BADGE_PROMOTION_LIST"
);
export const GET_BADGE_PROMOTION_DETAIL = requestConstantsMaker(
  "GET_BADGE_PROMOTION_DETAIL"
);

export const GET_USER_FREEZE_RECORD = requestConstantsMaker(
  "GET_USER_FREEZE_RECORD"
);
export const GET_USER_TERMINATE_RECORD = requestConstantsMaker(
  "GET_USER_TERMINATE_RECORD"
);

export const GET_SPENDING_LIMIT = requestConstantsMaker("GET_SPENDING_LIMIT");
export const POST_CHANGE_SPENDING_LIMIT = requestConstantsMaker(
  "POST_CHANGE_SPENDING_LIMIT"
);

// Third Party Integrator
export const GET_INTEGRATOR_LIST = requestConstantsMaker("GET_INTEGRATOR_LIST");
export const GET_INTEGRATOR = requestConstantsMaker("GET_INTEGRATOR");
export const POST_CREATE_INTEGRATOR = requestConstantsMaker(
  "POST_CREATE_INTEGRATOR"
);
export const PUT_UPDATE_INTEGRATOR = requestConstantsMaker(
  "PUT_UPDATE_INTEGRATOR"
);
export const PUT_UPDATE_INTEGRATOR_PASSWORD = requestConstantsMaker(
  "PUT_UPDATE_INTEGRATOR_PASSWORD"
);

// Mall
export const GET_MALL_LIST = requestConstantsMaker("GET_MALL_LIST");
export const GET_MALL = requestConstantsMaker("GET_MALL");
export const PUT_UPDATE_MALL = requestConstantsMaker("PUT_UPDATE_MALL");

// Contactlass parking
export const GET_LICENSE_PLATE_RECORD_LIST = requestConstantsMaker(
  "GET_LICENSE_PLATE_RECORD_LIST"
);
export const GET_CREDIT_CARD_LIST_BY_MEMBER_ID = requestConstantsMaker(
  "GET_CREDIT_CARD_LIST_BY_MEMBER_ID"
);
export const GET_LICENSE_PLATE_RECORD_WITH_PARKING_HISTORY =
  requestConstantsMaker("GET_LICENSE_PLATE_RECORD_WITH_PARKING_HISTORY");
export const GET_LICENSE_PLATE_RECORD_WITH_PARKING_HISTORY_DETAIL =
  requestConstantsMaker("GET_LICENSE_PLATE_RECORD_WITH_PARKING_HISTORY_DETAIL");
export const GET_PARK_HISTORY_WITH_TRANSACTION = requestConstantsMaker(
  "GET_PARK_HISTORY_WITH_TRANSACTION"
);
export const PUT_EXIT_CARPARK = requestConstantsMaker("PUT_EXIT_CARPARK");
export const PUT_SETTLE_OUTSTANDING_PAYMENT = requestConstantsMaker(
  "PUT_SETTLE_OUTSTANDING_PAYMENT"
);

// Contactlass parking(simulation)
export const PUT_VERIFY_LICENSE_PLATE = requestConstantsMaker(
  "PUT_VERIFY_LICENSE_PLATE"
);
export const POST_ENTER_CAR_PARK = requestConstantsMaker("POST_ENTER_CAR_PARK");
export const PUT_EXIT_CAR_PARK_SYSTEM = requestConstantsMaker(
  "PUT_EXIT_CAR_PARK_SYSTEM"
);
export const PUT_UPDATE_PARKING_TIME = requestConstantsMaker(
  "PUT_UPDATE_PARKING_TIME"
);
export const POST_PAY_BY_OCTOPUS = requestConstantsMaker("POST_PAY_BY_OCTOPUS");
export const PUT_INVALIDATE_CREDIT_CARD = requestConstantsMaker(
  "PUT_INVALIDATE_CREDIT_CARD"
);
export const GET_CARPARK_LIST = requestConstantsMaker("GET_CARPARK_LIST");

// Banner
export const GET_BANNER_LIST = requestConstantsMaker("GET_BANNER_LIST");
export const GET_BANNER_BY_ID = requestConstantsMaker("GET_BANNER_BY_ID");
export const POST_CREATE_BANNER = requestConstantsMaker("POST_CREATE_BANNER");
export const PUT_UPDATE_BANNER = requestConstantsMaker("PUT_UPDATE_BANNER");
export const GET_BANNER_REDIRECT_LIST =
  requestConstantsMaker("GET_REDIRECT_LIST");
export const PUT_UPDATE_BANNER_ENABLED = requestConstantsMaker(
  "PUT_UPDATE_BANNER_ENABLED"
);

// Banner Category
export const GET_BANNER_CATEGORY_LIST = requestConstantsMaker(
  "GET_BANNER_CATEGORY_LIST"
);

// Banner Area
export const GET_BANNER_AREA_LIST = requestConstantsMaker(
  "GET_BANNER_AREA_LIST"
);

// Dynamic Banner List
export const GET_DYNAMIC_BANNER_LIST = requestConstantsMaker(
  "GET_DYNAMIC_BANNER_LIST"
);
export const GET_DYNAMIC_BANNER_LIST_BY_ID = requestConstantsMaker(
  "GET_DYNAMIC_BANNER_LIST_BY_ID"
);
export const PUT_UPDATE_DYNAMIC_BANNER_LIST = requestConstantsMaker(
  "PUT_UPDATE_DYNAMIC_BANNER_LIST"
);

// Homepage banner
export const GET_HOMEPAGE_BANNER_LIST = requestConstantsMaker(
  "GET_HOMEPAGE_BANNER_LIST"
);
export const GET_HOMEPAGE_BANNER_BY_ID = requestConstantsMaker(
  "GET_HOMEPAGE_BANNER_BY_ID"
);
export const POST_CREATE_HOMEPAGE_BANNER = requestConstantsMaker(
  "POST_CREATE_HOMEPAGE_BANNER"
);
export const PUT_UPDATE_HOMEPAGE_BANNER = requestConstantsMaker(
  "PUT_UPDATE_HOMEPAGE_BANNER"
);
export const PUT_DISABLE_HOMEPAGE_BANNER = requestConstantsMaker(
  "PUT_DISABLE_HOMEPAGE_BANNER"
);
export const PUT_ENABLE_HOMEPAGE_BANNER = requestConstantsMaker(
  "PUT_ENABLE_HOMEPAGE_BANNER"
);
export const GET_HOMEPAGE_REDIRECT_LIST = requestConstantsMaker(
  "GET_HOMEPAGE_REDIRECT_LIST"
);

// Hotdeal
export const GET_HOT_DEAL_LIST = requestConstantsMaker("GET_HOT_DEAL_LIST");
export const GET_HOT_DEAL_FULL_LIST = requestConstantsMaker(
  "GET_HOT_DEAL_FULL_LIST"
);
export const GET_HOT_DEAL = requestConstantsMaker("GET_HOT_DEAL");
export const POST_HOT_DEAL = requestConstantsMaker("POST_HOT_DEAL");
export const DELETE_HOT_DEAL = requestConstantsMaker("DELETE_HOT_DEAL");
export const UPDATE_HOT_DEAL = requestConstantsMaker("UPDATE_HOT_DEAL");
export const GET_FOODIE_GIFT_LIST = requestConstantsMaker(
  "GET_FOODIE_GIFT_LIST"
);
export const GET_FOODIE_COUPON_LIST = requestConstantsMaker(
  "GET_FOODIE_COUPON_LIST"
);
export const POST_UPLOAD_FOODIE_IMAGE = requestConstantsMaker(
  "POST_UPLOAD_FOODIE_IMAGE"
);
export const DELETE_HOT_DEAL_ITEM = requestConstantsMaker(
  "REACT_APP_API_URL_HOT_DEAL"
);

// Activity
export const GET_ACTIVITY_LIST = requestConstantsMaker("GET_ACTIVITY_LIST");
export const GET_ACTIVITY = requestConstantsMaker("GET_ACTIVITY");
export const POST_CREATE_ACTIVITY = requestConstantsMaker(
  "POST_CREATE_ACTIVITY"
);
export const PUT_UPDATE_ACTIVITY = requestConstantsMaker("PUT_UPDATE_ACTIVITY");
export const DELETE_ACTIVITY = requestConstantsMaker("DELETE_ACTIVITY");
export const PUT_UPDATE_ACTIVITY_ENABLED = requestConstantsMaker(
  "PUT_UPDATE_ACTIVITY_ENABLED"
);
export const PUT_ALLOCATE_ACTIVITY_QUOTA = requestConstantsMaker(
  "PUT_ALLOCATE_ACTIVITY_QUOTA"
);
export const GET_ACTIVITY_USED_REWARD_LIST = requestConstantsMaker(
  "GET_ACTIVITY_USED_REWARD_LIST"
);

// Activity Segment
export const GET_ACTIVITY_SEGMENTS = requestConstantsMaker(
  "GET_ACTIVITY_SEGMENTS"
);
export const POST_CREATE_ACTIVITY_SEGMENT = requestConstantsMaker(
  "POST_CREATE_ACTIVITY_SEGMENT"
);
export const PUT_UPDATE_ACTIVITY_SEGMENT = requestConstantsMaker(
  "PUT_UPDATE_ACTIVITY_SEGMENT"
);
export const DELETE_ACTIVITY_SEGMENT = requestConstantsMaker(
  "DELETE_ACTIVITY_SEGMENT"
);

// Sino Club Member
export const GET_SINO_CLUB_MEMBER_LIST = requestConstantsMaker(
  "GET_SINO_CLUB_MEMBER_LIST"
);
export const GET_SINO_CLUB_MEMBER = requestConstantsMaker(
  "GET_SINO_CLUB_MEMBER"
);
export const GET_SINO_UPGRADE_LIST = requestConstantsMaker(
  "GET_SINO_UPGRADE_LIST"
);
export const GET_SINO_PARKING_LIST = requestConstantsMaker(
  "GET_SINO_PARKING_LIST"
);
export const GET_SINO_DOC_LIST = requestConstantsMaker("GET_SINO_DOC_LIST");

// Estate
export const GET_SINO_ESTATE_LIST = requestConstantsMaker(
  "GET_SINO_ESTATE_LIST"
);
export const GET_NON_SINO_ESTATE_LIST = requestConstantsMaker(
  "GET_NON_SINO_ESTATE_LIST"
);
export const GET_ESTATE = requestConstantsMaker("GET_ESTATE");
export const POST_CREATE_ESTATE = requestConstantsMaker("POST_CREATE_ESTATE");
export const POST_UPDATE_ESTATE = requestConstantsMaker("POST_UPDATE_ESTATE");

// Sino Club P Member Referral Code
export const GET_P_MEMBER_REFERRAL_CODE_LIST = requestConstantsMaker(
  "GET_P_MEMBER_REFERRAL_CODE_LIST"
);
export const GET_P_MEMBER_REFERRAL_CODE = requestConstantsMaker(
  "GET_P_MEMBER_REFERRAL_CODE"
);
export const POST_CREATE_P_MEMBER_REFERRAL_CODE = requestConstantsMaker(
  "POST_CREATE_P_MEMBER_REFERRAL_CODE"
);
export const PUT_UPDATE_P_MEMBER_REFERRAL_CODE = requestConstantsMaker(
  "PUT_UPDATE_P_MEMBER_REFERRAL_CODE"
);
export const POST_UPDATE_P_MEMBER_REFERRAL_CODE_ENABLED = requestConstantsMaker(
  "POST_UPDATE_P_MEMBER_REFERRAL_CODE_ENABLED"
);
export const DELETE_P_MEMBER_REFERRAL_CODE = requestConstantsMaker(
  "DELETE_P_MEMBER_REFERRAL_CODE"
);
export const GET_MEMBERSHIP_TIER_LIST = requestConstantsMaker(
  "GET_MEMBERSHIP_TIER_LIST"
);
export const GET_MEMBER_MEMBERSHIP_TIER = requestConstantsMaker(
  "GET_MEMBER_MEMBERSHIP_TIER"
);
