import {
  faAddressCard,
  faReceipt,
  faGift,
  faQuestionCircle,
  faAd,
  faRssSquare,
  faCertificate,
  faDollarSign,
  faTicketAlt,
  faBuilding,
  faHome,
  faSuperscript,
  faCalendarAlt,
  faBell,
  faUser,
  faInbox,
  faParking,
  faCalendarCheck,
  faPlus,
  faRss,
  faUserCircle,
  faTable,
  faCarRear,
  faBolt,
  faIdBadge,
  faHouseUser,
  faSquareParking,
  faBullseye,
  faCity,
  faAdd,
  faImage,
  faImages,
  faStamp,
  faGem,
} from "@fortawesome/free-solid-svg-icons";
import permission from "../../../settings/permission";

let options = [
  {
    key: "member",
    label: "sidebar.member",
    leftIcon: faAddressCard,
    allowAuthority: permission.MEMBER.VIEW,
  },
  {
    key: "sinoClubMember",
    label: "sidebar.sinoclubmember",
    leftIcon: faIdBadge,
    allowAuthority: permission.SINO_CLUB_MEMBER.VIEW,
  },
  {
    key: "sinoClubMembershipUpgrade",
    label: "sidebar.sinoclubmemberupgrade",
    leftIcon: faHouseUser,
    allowAuthority: permission.SINO_CLUB_MEMBER_UPGRADE.VIEW,
  },
  {
    key: "sinoClubPMemberReferralCode",
    label: "sidebar.sinoClubPMemberReferralCode",
    leftIcon: faAdd,
    allowAuthority: permission.SINO_CLUB_P_MEMBER_REFERRAL_CODE.VIEW,
  },
  {
    key: "estate",
    label: "sidebar.estate",
    leftIcon: faCity,
    allowAuthority: permission.ESTATE.VIEW,
  },
  {
    key: "sinoParking",
    label: "sidebar.sinoclubparking",
    leftIcon: faSquareParking,
    allowAuthority: permission.SINO_PARKING.VIEW,
  },
  {
    key: "spendingTransaction",
    label: "sidebar.spendingTransaction",
    leftIcon: faReceipt,
    allowAuthority: permission.POINT_MANAGEMENT.VIEW,
  },
  {
    key: "gift",
    label: "sidebar.gift",
    leftIcon: faGift,
    allowAuthority: permission.GIFT.VIEW,
  },
  {
    key: "onlineEnquiry",
    label: "sidebar.onlineEnquiry",
    leftIcon: faQuestionCircle,
    allowAuthority: [],
  },
  {
    key: "mall",
    label: "sidebar.mall",
    leftIcon: faBuilding,
    allowAuthority: permission.MALL.VIEW,
  },
  {
    key: "shop",
    label: "sidebar.shop",
    leftIcon: faHome,
    allowAuthority: permission.SHOP.VIEW,
  },
  {
    key: "sdollar",
    label: "sidebar.sdollar",
    leftIcon: faDollarSign,
    allowAuthority: permission.SDOLLAR.VIEW,
  },
  {
    key: "coupon",
    label: "sidebar.sCoupon",
    leftIcon: faTicketAlt,
    allowAuthority: permission.COUPON.VIEW,
  },
  {
    key: "Banner",
    label: "sidebar.banner",
    leftIcon: faImage,
    allowAuthority: permission.BANNER.VIEW,
  },
  {
    key: "dynamicBannerList",
    label: "sidebar.bannerList",
    leftIcon: faImages,
    allowAuthority: permission.BANNER.VIEW,
  },
  // {
  //   key: "homepageBanner",
  //   label: "sidebar.homepageBanner",
  //   leftIcon: faTable,
  //   allowAuthority: permission.HOMEPAGEBANNER.VIEW,
  // },
  // {
  //   key: 'pointRegistration',
  //   label: 'sidebar.pointRegistration',
  //   leftIcon: faSortAmountUp,
  //   allowAuthority: permission.POINT_REGISTRATION.VIEW,
  // },

  {
    key: "crazyAd",
    label: "sidebar.crazyAd",
    leftIcon: faAd,
    allowAuthority: permission.CRAZY_AD,
  },
  // {
  //   key: "campaign",
  //   label: "sidebar.campaign",
  //   leftIcon: faCalendarAlt,
  //   allowAuthority: permission.CAMPAIGN.VIEW,
  // },
  {
    key: "mission",
    label: "sidebar.mission",
    leftIcon: faBullseye,
    allowAuthority: permission.ACTIVITY.VIEW,
  },
  {
    key: "stamp",
    label: "sidebar.stamp",
    leftIcon: faStamp,
    allowAuthority: permission.ACTIVITY.VIEW,
  },
  // {
  //   key: "hotDeals",
  //   label: "sidebar.hotDeals",
  //   leftIcon: faBolt,
  //   allowAuthority: permission.HOT_DEALS.VIEW,
  // },
  {
    key: "unpublishedPrivilege",
    label: "sidebar.unpublishedPrivilege",
    leftIcon: faGem,
    allowAuthority: permission.UNPUBLISHED_PRIVILEGE.VIEW,
  },
  {
    key: "badge",
    label: "sidebar.badge",
    leftIcon: faCertificate,
    allowAuthority: permission.BADGES.VIEW,
  },
  {
    key: "badgeOfferPromotion",
    label: "sidebar.badgeOfferPromotion",
    leftIcon: faRss,
    allowAuthority: permission.BADGE_OFFER_PROMOTION.VIEW,
  },
  {
    key: "memberTags",
    label: "sidebar.memberTags",
    leftIcon: faCertificate,
    allowAuthority: permission.MEMBER_TAG.VIEW,
  },
  {
    key: "spendingPointBonusRule",
    label: "sidebar.spendingPointBonusRule",
    leftIcon: faSuperscript,
    allowAuthority: permission.BONUS,
  },
  {
    key: "contactlessParking",
    label: "sidebar.contactlessParking",
    leftIcon: faCarRear,
    allowAuthority: permission.CONTACTLESSPARKING.VIEW,
  },
  {
    key: "parkingRecord",
    label: "sidebar.createParkingRecords",
    leftIcon: faParking,
    allowAuthority: permission.CREATE_PARKING_RECORDS.VIEW,
  },
  // {
  //   key: 'carpark',
  //   label: 'sidebar.carpark',
  //   leftIcon: faParking,
  //   allowAuthority: permission.PARKING,
  // },
  {
    key: "checkInEvent",
    label: "sidebar.checkInEvent",
    leftIcon: faCalendarCheck,
    allowAuthority: permission.CHECK_IN,
  },
  {
    key: "user",
    label: "sidebar.user",
    leftIcon: faUser,
    allowAuthority: permission.USER,
  },
  {
    key: "userNotification",
    label: "sidebar.userPushRecord",
    leftIcon: faBell,
    allowAuthority: permission.PUSH_NOTIFICATION.VIEW,
  },
  {
    key: "userInboxMessage",
    label: "sidebar.userInboxMessage",
    leftIcon: faInbox,
    allowAuthority: permission.INBOX_MESSAGE.VIEW,
  },
  {
    key: "merchantNotification",
    label: "sidebar.merchantPushRecord",
    leftIcon: faBell,
    allowAuthority: permission.PUSH_NOTIFICATION.VIEW,
  },
  {
    key: "merchantInboxMessage",
    label: "sidebar.merchantInboxMessage",
    leftIcon: faInbox,
    allowAuthority: permission.INBOX_MESSAGE.VIEW,
  },
  {
    key: "merchantCustomReferralPoint",
    label: "sidebar.merchantCustomReferralPoint",
    leftIcon: faPlus,
    allowAuthority: permission.MERCHANT_CUSTOM_REFERRAL_POINT.VIEW,
  },
  {
    key: "thirdPartyIntegrator",
    label: "sidebar.thirdPartyIntegrator",
    leftIcon: faUserCircle,
    allowAuthority: permission.THIRD_PARTY_INTEGRATOR,
  },
  {
    key: "OCR_Test",
    label: "sidebar.ocrTest",
    leftIcon: faUserCircle,
    allowAuthority: permission.THIRD_PARTY_INTEGRATOR,
  },
];
if (process.env.REACT_APP_SHOW_MALL_SECTION === "false") {
  options = options.filter((value) => value.key !== "mall");
}

export default options;
