import * as cons from "./constants";
import { uiActionMaker } from "../../helpers/reduxHelper";

export const toggleImageLightbox = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_IMAGE_LIGHTBOX, ...option });
export const toggleCreatePointAdjustmentTypeModal = (option) =>
  uiActionMaker({
    cons: cons.TOGGLE_CREATE_POINT_ADJUSTMENT_TYPE_MODAL,
    ...option,
  });
export const toggleReserveGiftModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_RESERVE_GIFT_MODAL, ...option });
export const toggleSelectPointRegistrationOptionModal = (option) =>
  uiActionMaker({
    cons: cons.TOGGLE_SELECT_POINT_REGISTRATION_OPTION_MODAL,
    ...option,
  });
export const toggleCreateUserRemarkModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_CREATE_USER_REMARK_MODAL, ...option });
export const toggleCreateUserRemarkTypeModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_CREATE_USER_REMARK_TYPE_MODAL, ...option });
export const toggleRejectTransactionModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_REJECT_TRANSACTION_MODAL, ...option });
export const toggleCreateOrUpdateGiftSkuModal = (option) =>
  uiActionMaker({
    cons: cons.TOGGLE_CREATE_OR_UPDATE_GIT_SKU_MODAL,
    ...option,
  });
export const toggleUpdateGiftSkuModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_UPDATE_GIT_SKU_MODAL, ...option });
export const toggleUpdateSCouponStockModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_UPDATE_S_COUPON_STOCK_MODAL, ...option });
export const toggleChangePasswordModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_CHANGE_PASSWORD_MODAL, ...option });
export const toggleUpdateTransactionModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_UPDATE_TRANSACTION_MODAL, ...option });
export const toggleTerminateMember = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_TERMINATE_MEMBER_MODAL, ...option });
export const toggleChangePhoneModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_CHANGE_PHONE_MODAL, ...option });
export const toggleFreezeMemberModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_FREEZE_MEMBER_MODAL, ...option });
export const toggleFreezeMemberRemarkModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_FREEZE_MEMBER_REMARK_MODAL, ...option });
export const toggleUnreezeMemberModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_UNFREEZE_MEMBER_MODAL, ...option });
export const toggleGiveSpecialInvitationBadgeModal = (option) =>
  uiActionMaker({
    cons: cons.TOGGLE_GIVE_SPECIAL_INVITATION_BADGE_MODAL,
    ...option,
  });
export const toggleRemoveSpecialInvitationBadgeModal = (option) =>
  uiActionMaker({
    cons: cons.TOGGLE_REMOVE_SPECIAL_INVITATION_BADGE_MODAL,
    ...option,
  });
export const toggleDirectCollectGiftModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_DIRECT_COLLECT_GIFT_MODAL, ...option });
export const toggleRemovePhoneNumberModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_REMOVE_PHONE_NUMBER_MODAL, ...option });
export const toggleGiftStockDetailModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_GIFT_STOCK_DETAIL_MODAL, ...option });
export const toggleGiftAlipayCouponCodeModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_GIFT_ALIPAY_COUPON_CODE_MODAL, ...option });
export const toggleSCouponStockDetailModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_S_COUPON_STOCK_DETAIL_MODAL, ...option });
export const toggleImportShopsModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_IMPORT_SHOPS_MODAL, ...option });
export const toggleEditGeneralRateModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_EDIT_GENERAL_RATE_MODAL, ...option });
export const toggleEditDailyPointLimitModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_EDIT_DAILY_POINT_LIMIT_MODAL, ...option });
export const toggleEditShortTnCModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_EDIT_SHORT_TNC_MODAL, ...option });
export const toggleEditCouponTnCModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_EDIT_COUPON_TNC_MODAL, ...option });

export const toggleCreateShopRemarkModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_CREATE_SHOP_REMARK_MODAL, ...option });
export const toggleCreateShopRemarkTypeModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_CREATE_SHOP_REMARK_TYPE_MODAL, ...option });
export const toggleCreateBalanceAdjustReasonModal = (option) =>
  uiActionMaker({
    cons: cons.TOGGLE_CREATE_BALANCE_ADJUST_REASON_MODAL,
    ...option,
  });

// export const toggleCreateSameRewardModal = option => (uiActionMaker({ cons: cons.TOGGLE_CREATE_SAME_REWARD_MODAL, ...option }));
export const toggleCreateDifferentRewardModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_CREATE_DIFFERENT_REWARD_MODAL, ...option });
export const toggleReserveCouponModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_RESERVE_COUPON_MODAL, ...option });
export const toggleCheckInQRCodeDetailModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_CHECK_IN_QRCODE_DETAIL_MODAL, ...option });
export const toggleAddExtraQRCodeModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_ADD_EXTRA_QRCODE_MODAL, ...option });

export const toggleChangeThirdPartyPasswordModal = (option) =>
  uiActionMaker({
    cons: cons.TOGGLE_CHANGE_THIRD_PARTY_PASSWORD_MODAL,
    ...option,
  });

export const toggleCreateOrUpdateIconModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_CREATE_OR_UPDATE_ICON_MODAL, ...option });

export const toggleExtendPointExpiryDateModal = (option) =>
  uiActionMaker({
    cons: cons.TOGGLE_EXTENT_POINT_EXPIRY_DATE_MODAL,
    ...option,
  });

export const toggleLicensePlateModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_LICENSE_PLATE_MODAL, ...option });
export const toggleBundledCreditCardModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_BUNDLED_CREDIT_CARD_MODAL, ...option });
export const toggleMembershipTierChangeModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_MEMBERSHIP_TIER_CHANGE_MODAL, ...option });

export const togglePrivilegePromotionCodeModal = (option) =>
  uiActionMaker({
    cons: cons.TOGGLE_PRIVILEGE_PROMOTION_CODE_MODAL,
    ...option,
  });
export const toggleAddExtraPrivilegeCodeModal = (option) =>
  uiActionMaker({
    cons: cons.TOGGLE_ADD_EXTRA_PRIVILEGE_CODE_MODAL,
    ...option,
  });
export const toggleImportMemberTagsModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_IMPORT_MEMBER_TAGS_MODAL, ...option });
export const toggleRemoveMemberTagsModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_REMOVE_MEMBER_TAGS_MODAL, ...option });
export const toggleAddMemberTagModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_ADD_MEMBER_TAG_MODAL, ...option });

export const toggleEditGiftTnCModal = (option) =>
  uiActionMaker({ cons: cons.TOGGLE_EDIT_GIFT_TNC_MODAL, ...option });
