import React, { Component } from "react";

import { Route, withRouter, Switch } from "react-router-dom";
import { connect } from "react-redux";
import asyncComponent from "../../helpers/AsyncFunc";
import permission from "../../settings/permission";

const routes = [
  {
    path: "",
    component: asyncComponent(() => import("../Member/index.js")),
    allowAuthority: permission.MEMBER.VIEW,
  },
  {
    path: "banner",
    component: asyncComponent(() => import("../Banner/index.js")),
    allowAuthority: permission.BANNER.VIEW,
  },
  {
    path: "banner/create",
    component: asyncComponent(() => import("../Banner/create.js")),
    allowAuthority: permission.BANNER.ADD,
  },
  {
    path: "banner/edit/:id",
    component: asyncComponent(() => import("../Banner/edit.js")),
    allowAuthority: permission.BANNER.EDIT,
  },
  {
    path: "banner/view/:id",
    component: asyncComponent(() => import("../Banner/view.js")),
    allowAuthority: permission.BANNER.VIEW,
  },
  {
    path: "dynamicBannerList",
    component: asyncComponent(() => import("../DynamicBannerList/index.js")),
    allowAuthority: permission.BANNER.VIEW,
  },
  {
    path: "dynamicBannerList/edit/:id",
    component: asyncComponent(() => import("../DynamicBannerList/edit.js")),
    allowAuthority: permission.BANNER.EDIT,
  },
  {
    path: "dynamicBannerList/view/:id",
    component: asyncComponent(() => import("../DynamicBannerList/view.js")),
    allowAuthority: permission.BANNER.VIEW,
  },
  {
    path: "dashboard",
    component: asyncComponent(() => import("../Member/index.js")),
    allowAuthority: permission.MEMBER.VIEW,
  },
  // {
  //   path: "homepageBanner",
  //   component: asyncComponent(() => import("../HomepageBanner/index.js")),
  //   allowAuthority: permission.HOMEPAGEBANNER.VIEW,
  // },
  // {
  //   path: "homepageBanner/create",
  //   component: asyncComponent(() => import("../HomepageBanner/create.js")),
  //   allowAuthority: permission.HOMEPAGEBANNER.ADD,
  // },
  // {
  //   path: "homepageBanner/edit/:id",
  //   component: asyncComponent(() => import("../HomepageBanner/edit.js")),
  //   allowAuthority: permission.HOMEPAGEBANNER.EDIT,
  // },
  {
    path: "contactlessParking",
    component: asyncComponent(() => import("../ContactlessParking/index.js")),
    allowAuthority: permission.CONTACTLESSPARKING.VIEW,
  },
  {
    path: "parkingRecord",
    component: asyncComponent(() => import("../ParkingRecord/index.js")),
    allowAuthority: permission.CREATE_PARKING_RECORDS.VIEW,
  },
  {
    path: "parkingRecord/create",
    component: asyncComponent(() => import("../ParkingRecord/create.js")),
    allowAuthority: permission.CREATE_PARKING_RECORDS.ADD,
  },
  {
    path: "contactlessParking/edit/:id",
    component: asyncComponent(() => import("../ContactlessParking/edit.js")),
    allowAuthority: permission.CONTACTLESSPARKING.VIEW,
  },
  {
    path: "member",
    component: asyncComponent(() => import("../Member/index.js")),
    allowAuthority: permission.MEMBER.VIEW,
  },
  {
    path: "member/view/:userId",
    component: asyncComponent(() => import("../Member/view.js")),
    allowAuthority: permission.MEMBER.VIEW,
  },
  {
    path: "member/edit/:userId",
    component: asyncComponent(() => import("../Member/edit.js")),
    allowAuthority: permission.MEMBER.EDIT,
  },
  {
    path: "member/create",
    component: asyncComponent(() => import("../Member/create.js")),
    allowAuthority: permission.MEMBER.ADD,
  },
  {
    path: "spendingTransaction",
    component: asyncComponent(() => import("../SpendingTransaction/index.js")),
    allowAuthority: permission.POINT_MANAGEMENT.VIEW,
  },
  {
    path: "gift",
    component: asyncComponent(() => import("../Gift/index.js")),
    allowAuthority: permission.GIFT.VIEW,
  },
  {
    path: "gift/create",
    component: asyncComponent(() => import("../Gift/create.js")),
    allowAuthority: permission.GIFT.ADD,
  },
  {
    path: "gift/edit/:id",
    component: asyncComponent(() => import("../Gift/edit.js")),
    allowAuthority: permission.GIFT.EDIT,
  },
  {
    path: "receiptScanning",
    component: asyncComponent(() => import("../ReceiptScanning/index.js")),
    allowAuthority: ["ROLE_GOD"],
  },
  {
    path: "onlineEnquiry",
    component: asyncComponent(() => import("../OnlineEnquiry/index.js")),
    allowAuthority: [],
  },
  {
    path: "onlineEnquiry/edit/:id",
    component: asyncComponent(() => import("../OnlineEnquiry/edit.js")),
    allowAuthority: [],
  },
  {
    path: "sdollar",
    component: asyncComponent(() => import("../SDollar/index.js")),
    allowAuthority: permission.SDOLLAR.VIEW,
  },
  {
    path: "sdollar/view/:id",
    component: asyncComponent(() => import("../SDollar/view.js")),
    allowAuthority: permission.SDOLLAR.VIEW,
  },
  // {
  //   path: 'sdollar/edit/:id',
  //   component: asyncComponent(() => import('../SDollar/edit.js')),
  //   allowAuthority: permission.SDOLLAR.EDIT,
  // },
  {
    path: "mall",
    component: asyncComponent(() => import("../Mall/index.js")),
    allowAuthority: permission.MALL.VIEW,
  },
  {
    path: "mall/edit/:id",
    component: asyncComponent(() => import("../Mall/edit.js")),
    allowAuthority: permission.MALL.EDIT,
  },
  {
    path: "shop",
    component: asyncComponent(() => import("../Shop/index.js")),
    allowAuthority: permission.SHOP.VIEW,
  },
  {
    path: "shop/create",
    component: asyncComponent(() => import("../Shop/create.js")),
    allowAuthority: permission.SHOP.ADD,
  },
  {
    path: "shop/view/:id",
    component: asyncComponent(() => import("../Shop/view.js")),
    allowAuthority: permission.SHOP.VIEW,
  },
  {
    path: "crazyAd",
    component: asyncComponent(() => import("../CrazyAd/index.js")),
    allowAuthority: permission.CRAZY_AD,
  },
  {
    path: "crazyAd/create",
    component: asyncComponent(() => import("../CrazyAd/create.js")),
    allowAuthority: permission.CRAZY_AD,
  },
  {
    path: "crazyAd/edit/:id",
    component: asyncComponent(() => import("../CrazyAd/edit.js")),
    allowAuthority: permission.CRAZY_AD,
  },
  {
    path: "mission",
    component: asyncComponent(() => import("../Activity/Mission/index.js")),
    allowAuthority: permission.ACTIVITY.VIEW,
  },
  {
    path: "mission/create",
    component: asyncComponent(() => import("../Activity/Mission/create.js")),
    allowAuthority: permission.ACTIVITY.ADD,
  },
  {
    path: "mission/edit/:id",
    component: asyncComponent(() => import("../Activity/Mission/edit.js")),
    allowAuthority: permission.ACTIVITY.EDIT,
  },
  {
    path: "mission/view/:id",
    component: asyncComponent(() => import("../Activity/Mission/view.js")),
    allowAuthority: permission.ACTIVITY.VIEW,
  },
  {
    path: "mission/edit/:missionId/memberSegment/create",
    component: asyncComponent(() =>
      import("../Activity/MemberSegment/MissionSegment/create.js")
    ),
    allowAuthority: permission.ACTIVITY.ADD,
  },
  {
    path: "mission/edit/:missionId/memberSegment/edit/:id",
    component: asyncComponent(() =>
      import("../Activity/MemberSegment/MissionSegment/edit.js")
    ),
    allowAuthority: permission.ACTIVITY.EDIT,
  },
  {
    path: "mission/view/:missionId/memberSegment/view/:id",
    component: asyncComponent(() =>
      import("../Activity/MemberSegment/MissionSegment/view.js")
    ),
    allowAuthority: permission.ACTIVITY.VIEW,
  },
  {
    path: "stamp",
    component: asyncComponent(() => import("../Activity/Stamp/index.js")),
    allowAuthority: permission.ACTIVITY.VIEW,
  },
  {
    path: "stamp/create",
    component: asyncComponent(() => import("../Activity/Stamp/create.js")),
    allowAuthority: permission.ACTIVITY.ADD,
  },
  {
    path: "stamp/edit/:id",
    component: asyncComponent(() => import("../Activity/Stamp/edit.js")),
    allowAuthority: permission.ACTIVITY.EDIT,
  },
  {
    path: "stamp/view/:id",
    component: asyncComponent(() => import("../Activity/Stamp/view.js")),
    allowAuthority: permission.ACTIVITY.VIEW,
  },
  {
    path: "stamp/edit/:stampId/memberSegment/create",
    component: asyncComponent(() =>
      import("../Activity/MemberSegment/StampSegment/create.js")
    ),
    allowAuthority: permission.ACTIVITY.ADD,
  },
  {
    path: "stamp/edit/:stampId/memberSegment/edit/:id",
    component: asyncComponent(() =>
      import("../Activity/MemberSegment/StampSegment/edit.js")
    ),
    allowAuthority: permission.ACTIVITY.EDIT,
  },
  {
    path: "stamp/view/:stampId/memberSegment/view/:id",
    component: asyncComponent(() =>
      import("../Activity/MemberSegment/StampSegment/view.js")
    ),
    allowAuthority: permission.ACTIVITY.VIEW,
  },
  // {
  //   path: "campaign",
  //   component: asyncComponent(() => import("../Campaign/index.js")),
  //   allowAuthority: permission.CAMPAIGN.VIEW,
  // },
  // {
  //   path: "campaign/create",
  //   component: asyncComponent(() => import("../Campaign/create.js")),
  //   allowAuthority: permission.CAMPAIGN.ADD,
  // },
  // {
  //   path: "campaign/edit/:id",
  //   component: asyncComponent(() => import("../Campaign/edit.js")),
  //   allowAuthority: permission.CAMPAIGN.EDIT,
  // },
  {
    path: "hotDeals",
    component: asyncComponent(() => import("../HotDeals/index.js")),
    allowAuthority: permission.HOT_DEALS.VIEW,
  },
  {
    path: "hotDeals/create",
    component: asyncComponent(() => import("../HotDeals/create.js")),
    allowAuthority: permission.HOT_DEALS.ADD,
  },
  {
    path: "hotDeals/edit/:id",
    component: asyncComponent(() => import("../HotDeals/edit.js")),
    allowAuthority: permission.HOT_DEALS.EDIT,
  },
  {
    path: "coupon",
    component: asyncComponent(() => import("../Coupon/index.js")),
    allowAuthority: permission.COUPON.VIEW,
  },
  {
    path: "coupon/create",
    component: asyncComponent(() => import("../Coupon/create.js")),
    allowAuthority: permission.COUPON.ADD,
  },
  {
    path: "coupon/edit/:id",
    component: asyncComponent(() => import("../Coupon/edit.js")),
    allowAuthority: permission.COUPON.EDIT,
  },
  // {
  //   path: 'pointRegistration',
  //   component: asyncComponent(() => import('../PointRegistration/index.js')),
  //   allowAuthority: permission.POINT_REGISTRATION.VIEW,
  // },
  // {
  //   path: 'pointRegistration/create',
  //   component: asyncComponent(() => import('../PointRegistration/create.js')),
  //   allowAuthority: permission.POINT_REGISTRATION.ADD,
  // },
  // {
  //   path: 'pointRegistration/edit/:id',
  //   component: asyncComponent(() => import('../PointRegistration/edit.js')),
  //   allowAuthority: permission.POINT_REGISTRATION.EDIT,
  // },
  // {
  //   path: "promotion",
  //   component: asyncComponent(() => import("../Promotion/index.js")),
  //   allowAuthority: permission.FEED,
  // },
  // {
  //   path: "promotion/create",
  //   component: asyncComponent(() => import("../Promotion/create.js")),
  //   allowAuthority: permission.FEED,
  // },
  // {
  //   path: "promotion/edit/:id",
  //   component: asyncComponent(() => import("../Promotion/edit.js")),
  //   allowAuthority: permission.FEED,
  // },
  {
    path: "unpublishedPrivilege",
    component: asyncComponent(() => import("../UnpublishedPrivilege/index.js")),
    allowAuthority: permission.UNPUBLISHED_PRIVILEGE.VIEW,
  },
  {
    path: "unpublishedPrivilege/create",
    component: asyncComponent(() =>
      import("../UnpublishedPrivilege/create.js")
    ),
    allowAuthority: permission.UNPUBLISHED_PRIVILEGE.ADD,
  },
  {
    path: "unpublishedPrivilege/edit/:id",
    component: asyncComponent(() => import("../UnpublishedPrivilege/edit.js")),
    allowAuthority: permission.UNPUBLISHED_PRIVILEGE.EDIT,
  },
  {
    path: "unpublishedPrivilege/view/:id",
    component: asyncComponent(() => import("../UnpublishedPrivilege/view.js")),
    allowAuthority: permission.UNPUBLISHED_PRIVILEGE.VIEW,
  },
  {
    path: "badge",
    component: asyncComponent(() => import("../Badge/index.js")),
    allowAuthority: permission.BADGES.VIEW,
  },
  {
    path: "badge/create",
    component: asyncComponent(() => import("../Badge/create.js")),
    allowAuthority: permission.BADGES.ADD,
  },
  {
    path: "badge/edit/:id",
    component: asyncComponent(() => import("../Badge/edit.js")),
    allowAuthority: permission.BADGES.EDIT,
  },
  {
    path: "badge/editSp/:id",
    component: asyncComponent(() => import("../Badge/editSp.js")),
    allowAuthority: permission.BADGES.EDIT_SP,
  },
  {
    path: "badgeOfferPromotion",
    component: asyncComponent(() => import("../BadgeOfferPromotion/index.js")),
    allowAuthority: permission.BADGE_OFFER_PROMOTION.VIEW,
  },
  {
    path: "badgeOfferPromotion/create",
    component: asyncComponent(() => import("../BadgeOfferPromotion/create.js")),
    allowAuthority: permission.BADGE_OFFER_PROMOTION.ADD,
  },
  {
    path: "badgeOfferPromotion/edit/:id",
    component: asyncComponent(() => import("../BadgeOfferPromotion/edit.js")),
    allowAuthority: permission.BADGE_OFFER_PROMOTION.EDIT,
  },
  {
    path: "memberTags",
    component: asyncComponent(() => import("../MemberTags/index.js")),
    allowAuthority: permission.MEMBER_TAG.VIEW,
  },
  {
    path: "memberTags/create",
    component: asyncComponent(() => import("../MemberTags/create.js")),
    allowAuthority: permission.MEMBER_TAG.ADD,
  },
  {
    path: "memberTags/edit/:id",
    component: asyncComponent(() => import("../MemberTags/edit.js")),
    allowAuthority: permission.MEMBER_TAG.EDIT,
  },
  {
    path: "memberTags/view/:id",
    component: asyncComponent(() => import("../MemberTags/view.js")),
    allowAuthority: permission.MEMBER_TAG.VIEW,
  },
  {
    path: "spendingPointBonusRule",
    component: asyncComponent(() =>
      import("../SpendingPointBonusRule/index.js")
    ),
    allowAuthority: permission.BONUS,
  },
  {
    path: "spendingPointBonusRule/create",
    component: asyncComponent(() =>
      import("../SpendingPointBonusRule/create.js")
    ),
    allowAuthority: permission.BONUS,
  },
  {
    path: "spendingPointBonusRule/edit/:id",
    component: asyncComponent(() =>
      import("../SpendingPointBonusRule/edit.js")
    ),
    allowAuthority: permission.BONUS,
  },
  {
    path: "user",
    component: asyncComponent(() => import("../UserManagement/index.js")),
    allowAuthority: permission.USER,
  },
  {
    path: "user/create",
    component: asyncComponent(() => import("../UserManagement/create.js")),
    allowAuthority: permission.USER,
  },
  {
    path: "userNotification",
    component: asyncComponent(() => import("../UserNotification/index.js")),
    allowAuthority: permission.PUSH_NOTIFICATION.VIEW,
  },
  {
    path: "userNotification/create",
    component: asyncComponent(() => import("../UserNotification/create.js")),
    allowAuthority: permission.PUSH_NOTIFICATION.ADD,
  },
  {
    path: "userNotification/edit/:id",
    component: asyncComponent(() => import("../UserNotification/edit.js")),
    allowAuthority: permission.PUSH_NOTIFICATION.EDIT,
  },
  {
    path: "merchantNotification",
    component: asyncComponent(() => import("../MerchantNotification/index.js")),
    allowAuthority: permission.PUSH_NOTIFICATION.VIEW,
  },
  {
    path: "merchantNotification/create",
    component: asyncComponent(() =>
      import("../MerchantNotification/create.js")
    ),
    allowAuthority: permission.PUSH_NOTIFICATION.ADD,
  },
  {
    path: "merchantNotification/edit/:id",
    component: asyncComponent(() => import("../MerchantNotification/edit.js")),
    allowAuthority: permission.PUSH_NOTIFICATION.EDIT,
  },
  {
    path: "userInboxMessage",
    component: asyncComponent(() => import("../UserInboxMessage/index.js")),
    allowAuthority: permission.INBOX_MESSAGE.VIEW,
  },
  {
    path: "userInboxMessage/create",
    component: asyncComponent(() => import("../UserInboxMessage/create.js")),
    allowAuthority: permission.INBOX_MESSAGE.ADD,
  },
  {
    path: "userInboxMessage/edit/:id",
    component: asyncComponent(() => import("../UserInboxMessage/edit.js")),
    allowAuthority: permission.INBOX_MESSAGE.EDIT,
  },
  {
    path: "merchantInboxMessage",
    component: asyncComponent(() => import("../MerchantInboxMessage/index.js")),
    allowAuthority: permission.INBOX_MESSAGE.VIEW,
  },
  {
    path: "merchantInboxMessage/create",
    component: asyncComponent(() =>
      import("../MerchantInboxMessage/create.js")
    ),
    allowAuthority: permission.INBOX_MESSAGE.ADD,
  },
  {
    path: "merchantInboxMessage/edit/:id",
    component: asyncComponent(() => import("../MerchantInboxMessage/edit.js")),
    allowAuthority: permission.INBOX_MESSAGE.EDIT,
  },
  {
    path: "merchantInboxMessage/view/:id",
    component: asyncComponent(() => import("../MerchantInboxMessage/view.js")),
    allowAuthority: permission.INBOX_MESSAGE.VIEW,
  },
  {
    path: "changePassword",
    component: asyncComponent(() => import("../ChangePassword/index.js")),
    allowAuthority: permission.CHANGE_PASSWORD,
  },
  // {
  //   path: 'carpark',
  //   component: asyncComponent(() => import('../CarPark/index.js')),
  //   allowAuthority: permission.PARKING,
  // },
  // {
  //   path: 'carpark/edit/:id',
  //   component: asyncComponent(() => import('../CarPark/edit.js')),
  //   allowAuthority: permission.PARKING,
  // },

  {
    path: "checkInEvent",
    component: asyncComponent(() => import("../CheckInEvent/index.js")),
    allowAuthority: permission.CHECK_IN,
  },
  {
    path: "checkInEvent/create",
    component: asyncComponent(() => import("../CheckInEvent/create.js")),
    allowAuthority: permission.CHECK_IN,
  },
  {
    path: "checkInEvent/edit/:id",
    component: asyncComponent(() => import("../CheckInEvent/edit.js")),
    allowAuthority: permission.CHECK_IN,
  },
  {
    path: "merchantCustomReferralPoint",
    component: asyncComponent(() =>
      import("../MerchantCustomReferralPoint/index.js")
    ),
    allowAuthority: permission.MERCHANT_CUSTOM_REFERRAL_POINT.VIEW,
  },
  {
    path: "merchantCustomReferralPoint/create",
    component: asyncComponent(() =>
      import("../MerchantCustomReferralPoint/create.js")
    ),
    allowAuthority: permission.MERCHANT_CUSTOM_REFERRAL_POINT.ADD,
  },
  {
    path: "merchantCustomReferralPoint/edit/:id",
    component: asyncComponent(() =>
      import("../MerchantCustomReferralPoint/edit.js")
    ),
    allowAuthority: permission.MERCHANT_CUSTOM_REFERRAL_POINT.EDIT,
  },
  {
    path: "thirdPartyIntegrator",
    component: asyncComponent(() => import("../ThirdPartyIntegrator/index.js")),
    allowAuthority: permission.THIRD_PARTY_INTEGRATOR,
  },
  {
    path: "thirdPartyIntegrator/create",
    component: asyncComponent(() =>
      import("../ThirdPartyIntegrator/create.js")
    ),
    allowAuthority: permission.THIRD_PARTY_INTEGRATOR,
  },
  {
    path: "thirdPartyIntegrator/edit/:id",
    component: asyncComponent(() => import("../ThirdPartyIntegrator/edit.js")),
    allowAuthority: permission.THIRD_PARTY_INTEGRATOR,
  },
  {
    path: "OCR_Test",
    component: asyncComponent(() => import("../OCRTest/index.js")),
    allowAuthority: permission.THIRD_PARTY_INTEGRATOR,
  },
  // Sino Club Member
  {
    path: "sinoClubMember",
    component: asyncComponent(() => import("../SinoClubMember/index.js")),
    allowAuthority: permission.SINO_CLUB_MEMBER.VIEW,
  },
  {
    path: "sinoClubMember/view/:id",
    component: asyncComponent(() => import("../SinoClubMember/view.js")),
    allowAuthority: permission.SINO_CLUB_MEMBER.VIEW,
  },
  {
    path: "sinoClubMember/edit/:id",
    component: asyncComponent(() => import("../SinoClubMember/edit.js")),
    allowAuthority: permission.SINO_CLUB_MEMBER.ADD,
  },
  {
    path: "sinoClubMember/create",
    component: asyncComponent(() => import("../SinoClubMember/create.js")),
    allowAuthority: permission.SINO_CLUB_MEMBER.ADD,
  },
  {
    path: "sinoClubMember/import",
    component: asyncComponent(() => import("../SinoClubMember/import.js")),
    allowAuthority: permission.SINO_CLUB_MEMBER.ADD,
  },
  {
    path: "sinoClubMembershipUpgrade",
    component: asyncComponent(() =>
      import("../SinoClubMembershipUpgrade/index.js")
    ),
    allowAuthority: permission.SINO_CLUB_MEMBER_UPGRADE.VIEW,
  },
  {
    path: "sinoParking",
    component: asyncComponent(() => import("../SinoParking/index.js")),
    allowAuthority: permission.SINO_CLUB_MEMBER.VIEW,
  },
  {
    path: "estate",
    component: asyncComponent(() => import("../Estate/index.js")),
    allowAuthority: permission.ESTATE.VIEW,
  },
  {
    path: "estate/edit/:id",
    component: asyncComponent(() => import("../Estate/edit.js")),
    allowAuthority: permission.ESTATE.EDIT,
  },
  {
    path: "estate/create",
    component: asyncComponent(() => import("../Estate/create.js")),
    allowAuthority: permission.ESTATE.ADD,
  },
  {
    path: "OCR_Test",
    component: asyncComponent(() => import("../OCRTest/index.js")),
    allowAuthority: permission.THIRD_PARTY_INTEGRATOR,
  },
  {
    path: "sinoClubPMemberReferralCode",
    component: asyncComponent(() =>
      import("../SinoClubPMemberReferralCode/index.js")
    ),
    allowAuthority: permission.SINO_CLUB_P_MEMBER_REFERRAL_CODE.VIEW,
  },
  {
    path: "sinoClubPMemberReferralCode/create",
    component: asyncComponent(() =>
      import("../SinoClubPMemberReferralCode/create.js")
    ),
    allowAuthority: permission.SINO_CLUB_P_MEMBER_REFERRAL_CODE.ADD,
  },
  {
    path: "sinoClubPMemberReferralCode/edit/:id",
    component: asyncComponent(() =>
      import("../SinoClubPMemberReferralCode/edit.js")
    ),
    allowAuthority: permission.SINO_CLUB_P_MEMBER_REFERRAL_CODE.EDIT,
  },
];

class AppRouter extends Component {
  render() {
    const { style, authority } = this.props;
    return (
      <div style={style}>
        <Switch>
          <Route
            path="/"
            exact
            component={asyncComponent(() => import("../Root/index.js"))}
          />
          {routes.map((singleRoute) => {
            const { path, exact, allowAuthority, ...otherProps } = singleRoute;

            if (allowAuthority.some((s) => authority.includes(s))) {
              return (
                <Route
                  exact={exact !== false}
                  key={singleRoute.path}
                  path={`/${singleRoute.path}`}
                  {...otherProps}
                />
              );
            }
            return null;
          })}
        </Switch>
      </div>
    );
  }
}

export default withRouter(
  connect((state) => ({
    locale: state.LanguageSwitcher.language.locale,
    authority: state.getMyAuthority.data,
  }))(AppRouter)
);
